import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";

const PieChartComponent = (props) => {
  const { data, isTax = false, flag } = props;
  return (
    <PieChart
      series={[
        {
          data: data,
        },
      ]}
      width={350}
      height={flag === "invest" ? 600 : flag === "usa-income" ? 750 : 400}
      legend={{
        direction: isTax ? "row" : "column",
        position: {
          vertical: "bottom",
          horizontal: "right",
        },
      }}
    />
  );
};
export default PieChartComponent;
