import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import "./HouseAffordabilityCalc.scss";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import InputComponent from "../../Common/InputComponent/InputComponent";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import PieChartComponent from "../../Common/PieChart/PieChart";

function HouseAffordabilityCalc() {
  const [annualHouseHoldAmount, setAnnualHouseHoldAmount] = useState(100000);
  const [loanTerm, setLoanTerm] = useState(30);
  const [interestRate, setInterestRate] = useState(6.161);
  const [monthlyDebtPayback, setMonthlyDebtPayback] = useState(0);
  const [downPayment, setDownPayment] = useState(20);
  const [propertyTax, setPropertyTax] = useState(1.5);
  const [HOA, setHOA] = useState(0);
  const [insurance, setInsurance] = useState(0.5);
  const [dtiRatio, setDtiRation] = useState("Conventional loan (28/36 rule)");
  const [maxLoanAmount, setMaxLoanAmount] = useState(0);
  const [totalHousePrice, setTotalHousePrice] = useState(0);
  const [downPaymentAmount, setDownPaymentAmount] = useState(0);
  const [FHAUpfrontPremium, setFHAUpfrontPremium] = useState(0);
  const [frontEndDtiRatio, setFrontEndDtiRatio] = useState(0);
  const [backEndDtiRatio, setBackEndDtiRatio] = useState(0);
  const [mipPayment, setMipPayment] = useState(0);
  const [vaFundingFee, setVaFundingFee] = useState(0);
  const [totalMonthlyCost, setTotalMonthlyCost] = useState(0);
  const [estClosingCost, setEstClosingCost] = useState(0);
  const [monthlyMortgagePayment, setMonthlyMortgagePayment] = useState(0);
  const [annualPropertyCost, setAnnualPropertyCost] = useState(0);
  const [insuranceCost, setInsuranceCost] = useState(0);
  const [maintenanceCost, setMaintenanceCost] = useState(0);
  const [hoaCost, setHOACost] = useState(0);
  const [oneTimePayClosing, setOneTimePayClosing] = useState(0);

  const selectBoxData = [
    "Conventional loan (28/36 rule)",
    "FHA loan (31% front-end, 43% back-end)",
    "VA loan (41%)",
    "10%",
    "15%",
    "20%",
    "25%",
    "30%",
    "35%",
    "40%",
    "45%",
    "50%",
  ];

  useEffect(() => {
    const getVADtiValue = () => {
      if (+downPayment < 5) {
        return [38.0806, 39.1977];
      } else if (+downPayment > 10) {
        return [42.058, 42.1469];
      } else {
        return [38.159, 40.84515];
      }
    };

    const dtiRatioMapping = {
      "Conventional loan (28/36 rule)": [28, 36],
      "FHA loan (31% front-end, 43% back-end)": [31, 43],
      "VA loan (41%)": getVADtiValue(),
    };

    // Determine the DTI ratios
    let dtiRatioValue = dtiRatioMapping[dtiRatio];

    // If the dtiRatio is a percentage string like "10%", "15%", etc.
    if (!dtiRatioValue) {
      let customRatio = parseFloat(dtiRatio.replace("%", ""));
      dtiRatioValue = [customRatio, customRatio];
    }

    function calculateMaxHomePrice() {
      // Given values
      let maxTotalPayment;
      let maxHousingPayment;
      if (monthlyDebtPayback > 0) {
        maxTotalPayment =
          (annualHouseHoldAmount / 12) * (dtiRatioValue[1] / 100);
        maxHousingPayment = maxTotalPayment - monthlyDebtPayback;
      } else {
        maxTotalPayment =
          (annualHouseHoldAmount / 12) * (dtiRatioValue[0] / 100);
        maxHousingPayment = maxTotalPayment;
      }

      const loanTermYears = +loanTerm;
      const interestRateAnnual = +interestRate / 100;

      // Monthly rates and months in loan term
      const interestRateMonthly = +interestRateAnnual / 12;
      const loanTermMonths = +loanTermYears * 12;

      // Binary search to find home price
      let low = 0;
      let high = 10000000000000;
      let homePrice;

      while (high - low > 1) {
        homePrice = (+high + +low) / 2;

        // Mortgage payment formula components
        const monthlyPropertyTax = (+homePrice * +propertyTax) / (100 * 12); // 1.5% annual property tax
        const monthlyInsurance = (+homePrice * +insurance) / (100 * 12); // 0.5% annual insurance
        const hoaFees =
          dtiRatio === "FHA loan (31% front-end, 43% back-end)"
            ? ((+homePrice - +homePrice * (+downPayment / 100)) * +HOA) /
              100 /
              12
            : (+homePrice * +HOA) / (100 * 12);
        const monthlyMip =
          dtiRatio === "FHA loan (31% front-end, 43% back-end)" &&
          monthlyDebtPayback > 0
            ? ((+homePrice - +homePrice * (+downPayment / 100)) * 0.5) /
              100 /
              12
            : 0;
        const vaLoanFees =
          dtiRatio === "VA loan (41%)"
            ? ((+homePrice - +homePrice * (+downPayment / 100)) * 1.25) /
              100 /
              12
            : 0;

        const principalAndInterest =
          +maxHousingPayment -
          (+monthlyPropertyTax +
            +monthlyInsurance +
            +hoaFees +
            +monthlyMip +
            +vaLoanFees);

        const P = (1 - +downPayment / 100) * +homePrice; // Loan amount (80% of home price)
        const r = +interestRateMonthly;
        const n = +loanTermMonths;

        // Mortgage payment formula
        const mortgagePayment =
          (+P * (+r * Math.pow(1 + +r, +n))) / (Math.pow(1 + +r, +n) - 1);

        if (principalAndInterest > mortgagePayment) {
          low = +homePrice;
        } else {
          high = +homePrice;
        }
      }

      return low;
    }

    let totalHousePrice = calculateMaxHomePrice();

    const downPaymentAmount = (+totalHousePrice * +downPayment) / 100;
    const maxLoanAmount = +totalHousePrice - +downPaymentAmount;
    const estClosingCostValue = +totalHousePrice * 0.03;
    const FHAUpfrontPremiumValue = maxLoanAmount * 0.0175;

    const oneTimePayClosing =
      +downPaymentAmount +
      +estClosingCostValue +
      (dtiRatio === "FHA loan (31% front-end, 43% back-end)"
        ? +FHAUpfrontPremiumValue
        : 0);

    const monthlyInterestRate = interestRate / 100 / 12;
    const numberOfPayments = loanTerm * 12;
    const monthlyPayment =
      (+maxLoanAmount *
        +monthlyInterestRate *
        Math.pow(1 + +monthlyInterestRate, +numberOfPayments)) /
      (Math.pow(1 + +monthlyInterestRate, +numberOfPayments) - 1);

    const propertyTaxValue = (totalHousePrice * propertyTax) / 100;
    const hoaValue = (totalHousePrice * HOA) / 100;
    const insuranceValue = (totalHousePrice * insurance) / 100;
    const maintenanceCostValue = +totalHousePrice * 0.015;
    const mipPaymentValue = (maxLoanAmount * 0.5) / 100 / 12;
    const vaFundingFeeValue = (maxLoanAmount * 1.25) / 100 / 12;
    const totalMonthlyCostValue =
      (+propertyTaxValue +
        +hoaValue +
        +insuranceValue +
        +maintenanceCostValue) /
        12 +
      +monthlyPayment +
      (dtiRatio === "FHA loan (31% front-end, 43% back-end)"
        ? +mipPaymentValue
        : 0) +
      (dtiRatio === "VA loan (41%)" ? +vaFundingFeeValue : 0);

    const frontEnd =
      (((+propertyTaxValue + +hoaValue + +insuranceValue) / 12 +
        +monthlyPayment) *
        100) /
      (+annualHouseHoldAmount / 12);
    const backEnd =
      (((+propertyTaxValue + +hoaValue + +insuranceValue) / 12 +
        +monthlyPayment +
        +monthlyDebtPayback) *
        100) /
      (+annualHouseHoldAmount / 12);

    // Set all state values
    setMaxLoanAmount(maxLoanAmount);
    setTotalHousePrice(totalHousePrice);
    setDownPaymentAmount(downPaymentAmount);
    setEstClosingCost(estClosingCostValue);
    setFrontEndDtiRatio(frontEnd.toFixed(0));
    setBackEndDtiRatio(backEnd.toFixed(0));
    setOneTimePayClosing(oneTimePayClosing);
    setMonthlyMortgagePayment(monthlyPayment);
    setAnnualPropertyCost(propertyTaxValue);
    setHOACost(hoaValue);
    setInsuranceCost(insuranceValue);
    setMaintenanceCost(maintenanceCostValue);
    setTotalMonthlyCost(totalMonthlyCostValue);
    setFHAUpfrontPremium(FHAUpfrontPremiumValue);
    setMipPayment(mipPaymentValue);
    setVaFundingFee(vaFundingFeeValue);
  }, [
    annualHouseHoldAmount,
    loanTerm,
    interestRate,
    monthlyDebtPayback,
    downPayment,
    propertyTax,
    HOA,
    insurance,
    dtiRatio,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Annual Household Income"
                value={annualHouseHoldAmount}
                min={1}
                setState={setAnnualHouseHoldAmount}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Mortgage loan term"
                setState={setLoanTerm}
                value={loanTerm}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest rate"
                setState={setInterestRate}
                value={interestRate}
                min={0}
                max={199}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Monthly debt payback"
                setState={setMonthlyDebtPayback}
                value={monthlyDebtPayback}
                min={0}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Down payment"
                setState={setDownPayment}
                value={downPayment}
                min={0}
                max={199}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Property tax"
                setState={setPropertyTax}
                value={propertyTax}
                min={0}
                max={199}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="HOA or co-op fee"
                setState={setHOA}
                value={HOA}
                min={0}
                max={199}
              />

              <InputComponent
                isInput={true}
                flag="percentage"
                label="Insurance"
                setState={setInsurance}
                value={insurance}
                min={0}
                max={199}
              />

              <SelectBoxComponent
                value={dtiRatio}
                label="Debt-to-income (DTI) ratio"
                setValue={setDtiRation}
                data={selectBoxData}
                placeholder="Debt-to-income (DTI) ratio"
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                isInput={false}
                value={` You can afford a house up to <strong>$${totalHousePrice.toFixed(
                  2
                )}</strong> within which <strong>$${maxLoanAmount.toFixed(
                  2
                )}</strong> is the loan and <strong>$${downPaymentAmount.toFixed(
                  2
                )}</strong> is the down payment.`}
                date={true}
              />
              <InputComponent
                label="You can borrow"
                isInput={false}
                flag="dollar"
                value={maxLoanAmount}
              />
              <InputComponent
                label="Total price of the house"
                isInput={false}
                flag="dollar"
                value={totalHousePrice}
              />
              <InputComponent
                label="Down payment"
                isInput={false}
                flag="dollar"
                value={downPaymentAmount}
              />
              {dtiRatio === "FHA loan (31% front-end, 43% back-end)" && (
                <InputComponent
                  label="FHA upfront insurance premium (1.75%)"
                  isInput={false}
                  flag="dollar"
                  value={FHAUpfrontPremium}
                />
              )}
              <InputComponent
                label="Estimated closing cost (one time, assume 3%)"
                isInput={false}
                flag="dollar"
                value={estClosingCost}
              />
              {(dtiRatio === "Conventional loan (28/36 rule)" ||
                dtiRatio === "FHA loan (31% front-end, 43% back-end)" ||
                monthlyDebtPayback > 0) && (
                <>
                  <InputComponent
                    label="Front-end debt-to-income (DTI) ratio"
                    isInput={false}
                    date={true}
                    value={`${frontEndDtiRatio} %`}
                  />
                  <InputComponent
                    label="Back-end debt-to-income (DTI) ratio"
                    isInput={false}
                    date={true}
                    value={`${backEndDtiRatio} %`}
                  />
                </>
              )}
              <InputComponent
                label="Total one-time payment at closing"
                isInput={false}
                flag="dollar"
                value={oneTimePayClosing}
              />
              <InputComponent
                label="Monthly mortgage payment"
                isInput={false}
                flag="dollar"
                value={monthlyMortgagePayment}
              />
              {dtiRatio === "FHA loan (31% front-end, 43% back-end)" && (
                <InputComponent
                  label="Monthly MIP payment (0.5%)"
                  isInput={false}
                  flag="dollar"
                  value={mipPayment}
                />
              )}
              {dtiRatio === "VA loan (41%)" && (
                <InputComponent
                  label="Monthly VA loan funding fee (1.25%)"
                  isInput={false}
                  flag="dollar"
                  value={vaFundingFee}
                />
              )}
              <InputComponent
                label="Annual property tax"
                isInput={false}
                flag="dollar"
                value={annualPropertyCost}
              />
              <InputComponent
                label="Annual HOA or co-op fee"
                isInput={false}
                flag="dollar"
                value={hoaCost}
              />
              <InputComponent
                label="Annual insurance cost"
                isInput={false}
                flag="dollar"
                value={insuranceCost}
              />
              <InputComponent
                label={`Estimated annual maintenance cost`}
                isInput={false}
                flag="dollar"
                value={maintenanceCost}
              />
              <InputComponent
                label="Total monthly cost on the house"
                isInput={false}
                flag="dollar"
                value={totalMonthlyCost}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ display: "flex" }}>
            <PieChartComponent
              data={[
                { id: 0, value: +maxLoanAmount, label: "You can borrow	" },
                {
                  id: 1,
                  value: +totalHousePrice,
                  label: "Total price of the house",
                },
                {
                  id: 2,
                  value: +downPaymentAmount,
                  label: "Down payment",
                },
              ]}
              flag="invest"
            />
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default HouseAffordabilityCalc;
