import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TableComponent.scss";
import {
  formatAmountIndian,
  StyledTableCell,
  StyledTableRow,
} from "../../../Utils/CommonFunction";

const TableComponent = (props) => {
  const { rows, headerData, isMinWidth, currency } = props;
  const allKeys = Object.keys(rows[0] || {});
  return (
    <TableContainer component={Paper} className="table-container">
      <Table
        sx={{ minWidth: isMinWidth || 700 }}
        aria-label="customized table"
        className="common-table"
      >
        <TableHead>
          <TableRow sx={{ border: "2px solid #295fa6" }}>
            {headerData?.map((item, index) => (
              <StyledTableCell key={index}>{item}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className="table-container">
          {rows.length > 0 ? (
            rows?.map((row, index) => (
              <StyledTableRow key={row?.name}>
                {allKeys.map((key) => (
                  <StyledTableCell key={key}>
                    {key === "period" ||
                    key === "month" ||
                    key === "name" ||
                    key === "tipPercentage" ||
                    key === "date" ||
                    key === "activity" ||
                    key === "energy" ||
                    key === "goal" ||
                    key === "day" ||
                    key === "mildWL" ||
                    key === "WL" ||
                    key === "extremeWL" ||
                    key === "value" ||
                    key === "year" ||
                    key === "networkAddress" ||
                    key === "usableHostRange" ||
                    key === "broadcastAddress" ||
                    key === "no" ||
                    key === "distance" ||
                    key === "time" ||
                    key === "pace" ||
                    key === "accumulatedPace" ||
                    key === "IRR" ||
                    key === "cashOnCashReturn"
                      ? row[key]
                      : formatAmountIndian(row[key], currency)}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))
          ) : (
            <StyledTableCell
              colSpan={headerData.length}
              // className="table-data-error"
            >
              No data found for your input value
            </StyledTableCell>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TableComponent;
