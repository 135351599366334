import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import PieChartComponent from "../../Common/PieChart/PieChart";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import TableComponent from "../../Common/TableComponent/TableComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
function BusinessLoanCalc() {
  const [loanAmountInput, setLoanAmountInput] = useState(10000);
  const [interestValue, setInterestValue] = useState(10);
  const [yearOfLoan, setYearOfLoan] = useState(5);
  const [originationFee, setOriginationFee] = useState(5);
  const [documentationFee, setDocumentationFee] = useState(750);
  const [otherFee, setOtherFee] = useState(0);
    const [tableView, setTableView] = useState(false);
  const [monthlyRecords, setMonthlyRecords] = useState([]);
  const [compound, setCompound] = useState("Monthly ( APR )");
  const [payBack, setPayBack] = useState("Every Month");

  const [paybackMonthly, setPaybackMonthly] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [interestFees, setInterestFees] = useState(0);
  const [realAPR, setRealAPR] = useState(0);
const [numberOfPayment,setNumberOfPayment] = useState(yearOfLoan * 12);

useEffect(() => {
  // Helper function to get the number of compounding periods per year
  const getCompoundingPeriods = (frequency) => {
    switch (frequency) {
      case "Annually ( APY )": return 1;
      case "Semi-annually": return 2;
      case "Quarterly": return 4;
      case "Monthly ( APR )": return 12;
      case "Semi-monthly": return 24;
      case "Biweekly": return 26;
      case "Weekly": return 52;
      case "Daily": return 365;
      case "Continuously": return Infinity;
      default: return 12;
    }
  };

  // Helper function to get the number of payback periods per year
  const getPaybackPeriods = (frequency) => {
    switch (frequency) {
      case "Every Day": return 365;
      case "Every Week": return 52;
      case "Every 2 Weeks": return 26;
      case "Every Half Month": return 24;
      case "Every Month": return 12;
      case "Every Quarter": return 4;
      case "Every 6 Month": return 2;
      case "Every Year": return 1;
      case "Interest Only":
      case "In The End": return 1;
      default: return 12;
    }
  };

  // Parse the loan-related inputs from the UI
  const loanAmount = +loanAmountInput;
  const originationFees = (+loanAmount * +originationFee  / 100);
  const totalFees = originationFees + +documentationFee + +otherFee;
  const totalLoanAmount = +loanAmount;
  const rateDecimal = +interestValue / 100;
  const loanTermYears = +yearOfLoan;

  // Get the number of compounding and payback periods per year based on user input
  const nCompoundsPerYear = getCompoundingPeriods(compound);
  const nPaybackPeriodsPerYear = getPaybackPeriods(payBack);

  // Calculate the total number of payback periods for the loan
  const totalPaybackPeriods = loanTermYears * nPaybackPeriodsPerYear;

  // Calculate the periodic interest rate
  const periodicRate = (Math.pow(1 + rateDecimal / nCompoundsPerYear, nCompoundsPerYear / nPaybackPeriodsPerYear) - 1);

  // Calculate the periodic payment
  const periodicPayment = (totalLoanAmount * periodicRate * Math.pow(1 + periodicRate, totalPaybackPeriods)) / 
                          (Math.pow(1 + periodicRate, totalPaybackPeriods) - 1);

  // Calculate total payments and total interest over the life of the loan
  const totalPayments = periodicPayment * totalPaybackPeriods;
  const totalInterest = totalPayments - totalLoanAmount;

  const realRate = (Math.pow(1 + rateDecimal / nCompoundsPerYear, nCompoundsPerYear) - 1) * 100;

  // const realRate = ((+totalInterest + +totalFees) / loanAmount / loanTermYears) * 100;

  // Generate the amortization schedule
  let remainingBalance = totalLoanAmount;
  const amortizationSchedule = [];

  for (let i = 0; i < totalPaybackPeriods; i++) {
    const interestForPeriod = remainingBalance * periodicRate;
    const principalForPeriod = periodicPayment - interestForPeriod;
    const endingBalance = remainingBalance - principalForPeriod;

    amortizationSchedule.push({
      period: i + 1,
      beginningBalance: remainingBalance.toFixed(2),
      interest: interestForPeriod.toFixed(2),
      principal: principalForPeriod.toFixed(2),
      endingBalance: endingBalance.toFixed(2),
    });

    remainingBalance = endingBalance;
  }

  // Update the state with the calculated values
  setNumberOfPayment(totalPaybackPeriods);
  setPaybackMonthly(periodicPayment.toFixed(2));
  setTotalPayment(totalPayments.toFixed(2));
  setTotalInterest(totalInterest.toFixed(2));
  setInterestFees((+totalInterest + +totalFees).toFixed(2));
  setRealAPR(realRate.toFixed(3));
  setMonthlyRecords(amortizationSchedule);

  // Optionally add validation and error handling here
  // Example: Validate that loanAmount, interestValue, and other fields are greater than zero
  
}, [
  loanAmountInput, 
  interestValue, 
  yearOfLoan, 
  originationFee, 
  documentationFee, 
  otherFee, 
  compound, 
  payBack
]);

  
  
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Loan Amount"
                value={loanAmountInput}
                setState={setLoanAmountInput}
                min={1}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Rate of Interest (p.a.)"
                setState={setInterestValue}
                min={0}
                max={999}
                value={interestValue}
              />
              <SelectBoxComponent
              value={compound}
              setValue={setCompound}
              data={[
                "Annually ( APY )",
                "Semi-annually",
                "Quarterly",
                "Monthly ( APR )",
                "Semi-monthly",
                "Biweekly",
                "Weekly",
                "Daily",
                "Continuously",
              ]}
              label="Compound"
            />{" "}
              <InputComponent
                isInput={true}
                flag="year"
                label="Loan Tenure"
                setState={setYearOfLoan}
                value={yearOfLoan}
                min={0}
                max={199}
              />
               <SelectBoxComponent
              value={payBack}
              setValue={setPayBack}
              data={[
                "Every Day",
                "Every Week",
                "Every 2 Week",
                "Every Half Month",
                "Every Month",
                "Every Quarter",
                "Every 6 Month",
                "Every Year",
                "Interest Only",
                "In The End",
              ]}
              label="Pay back"
            />{" "}
            <InputComponent
                isInput={true}
                flag="percentage"
                label="Origination Fee"
                setState={setOriginationFee}
                min={0}
                max={999}
                value={originationFee}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Documentation Fee"
                value={documentationFee}
                setState={setDocumentationFee}
                min={0}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Other Fee"
                value={otherFee}
                setState={setOtherFee}
                min={0}
                max={10000000000}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent 
              
              label={`Payback ${payBack}`}
                isInput={false}
                flag="dollar"
                value={paybackMonthly}
              />
              <InputComponent
                label={`Total of ${numberOfPayment} loan payments`}
                isInput={false}
                flag="dollar"
                value={totalPayment}
              />
              <InputComponent
                label="Interest"
                isInput={false}
                flag="dollar"
                value={totalInterest}
              />
              <InputComponent
                label="Interest + fee"
                isInput={false}
                flag="dollar"
                value={+interestFees}
              />
              <InputComponent
                label={`Real rate compound ${compound}`}
                isInput={false}
                value={realAPR}
                 date={true}
                flag="percentage"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +loanAmountInput, label: "Principal" },
                { id: 1, value: +totalInterest, label: "Interest" },
                { id: 2, value: +interestFees, label: "Fee" },
              ]}
            />
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">
              Your Amortization Details{" "}
              <span>
                (<u>Monthly</u>)
              </span>
            </Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={monthlyRecords}
                  headerData={[
                    "Month",
                    "Beginning Balance",
                    "Interest",
                    "Principal",
                    "Ending Balance",
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default BusinessLoanCalc;
